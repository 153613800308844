.lst-kix_list_14-1>li:before {
    content: "\0025cf  "
}

.lst-kix_list_14-3>li:before {
    content: "\0025cf  "
}

ul.lst-kix_list_28-0 {
    list-style-type: none
}

.lst-kix_list_21-8>li {
    counter-increment: lst-ctn-kix_list_21-8
}

.lst-kix_list_14-0>li:before {
    content: "\0025cf  "
}

.lst-kix_list_14-4>li:before {
    content: "o  "
}

ul.lst-kix_list_28-1 {
    list-style-type: none
}

.lst-kix_list_14-5>li:before {
    content: "\0025aa  "
}

.lst-kix_list_14-7>li:before {
    content: "o  "
}

.lst-kix_list_14-6>li:before {
    content: "\0025cf  "
}

ul.lst-kix_list_9-3 {
    list-style-type: none
}

ul.lst-kix_list_9-4 {
    list-style-type: none
}

ul.lst-kix_list_9-1 {
    list-style-type: none
}

ul.lst-kix_list_9-2 {
    list-style-type: none
}

ul.lst-kix_list_9-7 {
    list-style-type: none
}

ul.lst-kix_list_9-8 {
    list-style-type: none
}

.lst-kix_list_17-0>li {
    counter-increment: lst-ctn-kix_list_17-0
}

ul.lst-kix_list_9-5 {
    list-style-type: none
}

ul.lst-kix_list_9-6 {
    list-style-type: none
}

ul.lst-kix_list_28-2 {
    list-style-type: none
}

ul.lst-kix_list_28-3 {
    list-style-type: none
}

ul.lst-kix_list_28-4 {
    list-style-type: none
}

ul.lst-kix_list_28-5 {
    list-style-type: none
}

ul.lst-kix_list_28-6 {
    list-style-type: none
}

ul.lst-kix_list_9-0 {
    list-style-type: none
}

ul.lst-kix_list_28-7 {
    list-style-type: none
}

ul.lst-kix_list_28-8 {
    list-style-type: none
}

.lst-kix_list_14-2>li:before {
    content: "\0025aa  "
}

ol.lst-kix_list_23-2.start {
    counter-reset: lst-ctn-kix_list_23-2 0
}

ul.lst-kix_list_17-1 {
    list-style-type: none
}

.lst-kix_list_32-2>li:before {
    content: "\0025aa  "
}

ul.lst-kix_list_17-8 {
    list-style-type: none
}

.lst-kix_list_32-1>li:before {
    content: "o  "
}

.lst-kix_list_32-3>li:before {
    content: "\0025cf  "
}

ul.lst-kix_list_17-7 {
    list-style-type: none
}

ol.lst-kix_list_21-6.start {
    counter-reset: lst-ctn-kix_list_21-6 0
}

ul.lst-kix_list_17-6 {
    list-style-type: none
}

ul.lst-kix_list_17-5 {
    list-style-type: none
}

ul.lst-kix_list_17-4 {
    list-style-type: none
}

ul.lst-kix_list_17-3 {
    list-style-type: none
}

.lst-kix_list_14-8>li:before {
    content: "\0025aa  "
}

ul.lst-kix_list_17-2 {
    list-style-type: none
}

.lst-kix_list_32-0>li:before {
    content: "\0025cf  "
}

.lst-kix_list_5-0>li:before {
    content: "\0025cf  "
}

ol.lst-kix_list_22-3.start {
    counter-reset: lst-ctn-kix_list_22-3 0
}

.lst-kix_list_24-7>li:before {
    content: "o  "
}

ul.lst-kix_list_27-0 {
    list-style-type: none
}

ul.lst-kix_list_27-1 {
    list-style-type: none
}

.lst-kix_list_24-8>li:before {
    content: "\0025aa  "
}

ul.lst-kix_list_27-2 {
    list-style-type: none
}

.lst-kix_list_5-3>li:before {
    content: "\0025cf  "
}

.lst-kix_list_5-2>li:before {
    content: "\0025aa  "
}

.lst-kix_list_5-1>li:before {
    content: "\0025cf  "
}

.lst-kix_list_24-2>li:before {
    content: "\0025aa  "
}

.lst-kix_list_5-7>li:before {
    content: "o  "
}

ul.lst-kix_list_8-4 {
    list-style-type: none
}

ul.lst-kix_list_8-5 {
    list-style-type: none
}

.lst-kix_list_5-6>li:before {
    content: "\0025cf  "
}

.lst-kix_list_5-8>li:before {
    content: "\0025aa  "
}

ul.lst-kix_list_8-2 {
    list-style-type: none
}

ul.lst-kix_list_8-3 {
    list-style-type: none
}

ul.lst-kix_list_8-8 {
    list-style-type: none
}

.lst-kix_list_24-3>li:before {
    content: "\0025cf  "
}

ul.lst-kix_list_8-6 {
    list-style-type: none
}

.lst-kix_list_24-4>li:before {
    content: "o  "
}

ul.lst-kix_list_8-7 {
    list-style-type: none
}

ul.lst-kix_list_27-3 {
    list-style-type: none
}

.lst-kix_list_24-5>li:before {
    content: "\0025aa  "
}

ul.lst-kix_list_27-4 {
    list-style-type: none
}

.lst-kix_list_5-4>li:before {
    content: "o  "
}

ul.lst-kix_list_27-5 {
    list-style-type: none
}

ul.lst-kix_list_27-6 {
    list-style-type: none
}

.lst-kix_list_5-5>li:before {
    content: "\0025aa  "
}

ul.lst-kix_list_8-0 {
    list-style-type: none
}

ul.lst-kix_list_27-7 {
    list-style-type: none
}

ul.lst-kix_list_8-1 {
    list-style-type: none
}

ul.lst-kix_list_27-8 {
    list-style-type: none
}

.lst-kix_list_24-6>li:before {
    content: "\0025cf  "
}

.lst-kix_list_23-6>li:before {
    content: "" counter(lst-ctn-kix_list_23-6, decimal) ". "
}

.lst-kix_list_6-1>li:before {
    content: "o  "
}

.lst-kix_list_6-3>li:before {
    content: "\0025cf  "
}

.lst-kix_list_23-3>li:before {
    content: "" counter(lst-ctn-kix_list_23-3, decimal) ". "
}

.lst-kix_list_23-7>li:before {
    content: "" counter(lst-ctn-kix_list_23-7, lower-latin) ". "
}

.lst-kix_list_6-0>li:before {
    content: "\0025cf  "
}

.lst-kix_list_6-4>li:before {
    content: "o  "
}

.lst-kix_list_23-2>li:before {
    content: "" counter(lst-ctn-kix_list_23-2, lower-roman) ". "
}

ul.lst-kix_list_16-2 {
    list-style-type: none
}

ol.lst-kix_list_23-7.start {
    counter-reset: lst-ctn-kix_list_23-7 0
}

ul.lst-kix_list_16-1 {
    list-style-type: none
}

ul.lst-kix_list_16-0 {
    list-style-type: none
}

.lst-kix_list_23-0>li:before {
    content: "" counter(lst-ctn-kix_list_23-0, upper-roman) ". "
}

.lst-kix_list_23-8>li:before {
    content: "" counter(lst-ctn-kix_list_23-8, lower-roman) ". "
}

.lst-kix_list_23-1>li:before {
    content: "" counter(lst-ctn-kix_list_23-1, lower-latin) ". "
}

.lst-kix_list_6-2>li:before {
    content: "\0025aa  "
}

.lst-kix_list_24-1>li:before {
    content: "o  "
}

ul.lst-kix_list_16-8 {
    list-style-type: none
}

ul.lst-kix_list_16-7 {
    list-style-type: none
}

ul.lst-kix_list_16-6 {
    list-style-type: none
}

ul.lst-kix_list_16-5 {
    list-style-type: none
}

.lst-kix_list_24-0>li:before {
    content: "\0025cf  "
}

ul.lst-kix_list_16-4 {
    list-style-type: none
}

.lst-kix_list_6-8>li:before {
    content: "\0025aa  "
}

ul.lst-kix_list_16-3 {
    list-style-type: none
}

.lst-kix_list_6-5>li:before {
    content: "\0025aa  "
}

.lst-kix_list_6-7>li:before {
    content: "o  "
}

.lst-kix_list_23-4>li:before {
    content: "" counter(lst-ctn-kix_list_23-4, lower-latin) ". "
}

.lst-kix_list_23-5>li:before {
    content: "" counter(lst-ctn-kix_list_23-5, lower-roman) ". "
}

.lst-kix_list_6-6>li:before {
    content: "\0025cf  "
}

.lst-kix_list_7-4>li:before {
    content: "o  "
}

.lst-kix_list_7-6>li:before {
    content: "\0025cf  "
}

.lst-kix_list_22-2>li:before {
    content: "" counter(lst-ctn-kix_list_22-2, lower-roman) ". "
}

.lst-kix_list_22-6>li:before {
    content: "" counter(lst-ctn-kix_list_22-6, decimal) ". "
}

ol.lst-kix_list_17-0 {
    list-style-type: none
}

.lst-kix_list_22-2>li {
    counter-increment: lst-ctn-kix_list_22-2
}

.lst-kix_list_7-2>li:before {
    content: "\0025aa  "
}

.lst-kix_list_22-0>li:before {
    content: "(" counter(lst-ctn-kix_list_22-0, decimal) ") "
}

.lst-kix_list_22-8>li:before {
    content: "" counter(lst-ctn-kix_list_22-8, lower-roman) ". "
}

ol.lst-kix_list_22-8.start {
    counter-reset: lst-ctn-kix_list_22-8 0
}

.lst-kix_list_31-0>li:before {
    content: "\0025cf  "
}

ol.lst-kix_list_22-5.start {
    counter-reset: lst-ctn-kix_list_22-5 0
}

.lst-kix_list_13-7>li:before {
    content: "o  "
}

.lst-kix_list_7-8>li:before {
    content: "\0025aa  "
}

ol.lst-kix_list_23-0.start {
    counter-reset: lst-ctn-kix_list_23-0 0
}

.lst-kix_list_22-4>li:before {
    content: "" counter(lst-ctn-kix_list_22-4, lower-latin) ". "
}

.lst-kix_list_15-5>li:before {
    content: "\0025aa  "
}

.lst-kix_list_31-6>li:before {
    content: "\0025cf  "
}

.lst-kix_list_31-8>li:before {
    content: "\0025aa  "
}

.lst-kix_list_4-1>li:before {
    content: "o  "
}

.lst-kix_list_31-2>li:before {
    content: "\0025aa  "
}

.lst-kix_list_31-4>li:before {
    content: "o  "
}

.lst-kix_list_15-7>li:before {
    content: "o  "
}

ul.lst-kix_list_19-7 {
    list-style-type: none
}

ul.lst-kix_list_19-6 {
    list-style-type: none
}

.lst-kix_list_4-3>li:before {
    content: "\0025cf  "
}

.lst-kix_list_4-5>li:before {
    content: "\0025aa  "
}

ul.lst-kix_list_19-5 {
    list-style-type: none
}

ul.lst-kix_list_19-4 {
    list-style-type: none
}

ul.lst-kix_list_19-3 {
    list-style-type: none
}

.lst-kix_list_25-5>li:before {
    content: "\0025aa  "
}

ul.lst-kix_list_19-2 {
    list-style-type: none
}

ul.lst-kix_list_19-1 {
    list-style-type: none
}

ul.lst-kix_list_19-0 {
    list-style-type: none
}

.lst-kix_list_15-1>li:before {
    content: "o  "
}

.lst-kix_list_25-7>li:before {
    content: "o  "
}

.lst-kix_list_15-3>li:before {
    content: "\0025cf  "
}

.lst-kix_list_22-1>li {
    counter-increment: lst-ctn-kix_list_22-1
}

ul.lst-kix_list_19-8 {
    list-style-type: none
}

ul.lst-kix_list_29-0 {
    list-style-type: none
}

.lst-kix_list_32-4>li:before {
    content: "o  "
}

.lst-kix_list_33-4>li:before {
    content: "o  "
}

.lst-kix_list_12-3>li:before {
    content: "\0025cf  "
}

.lst-kix_list_32-6>li:before {
    content: "\0025cf  "
}

.lst-kix_list_12-1>li:before {
    content: "\0025cf  "
}

ul.lst-kix_list_29-1 {
    list-style-type: none
}

ul.lst-kix_list_29-2 {
    list-style-type: none
}

.lst-kix_list_33-0>li:before {
    content: "\0025cf  "
}

.lst-kix_list_33-2>li:before {
    content: "\0025aa  "
}

ul.lst-kix_list_29-3 {
    list-style-type: none
}

ul.lst-kix_list_29-4 {
    list-style-type: none
}

.lst-kix_list_32-8>li:before {
    content: "\0025aa  "
}

ul.lst-kix_list_29-5 {
    list-style-type: none
}

ul.lst-kix_list_29-6 {
    list-style-type: none
}

.lst-kix_list_23-6>li {
    counter-increment: lst-ctn-kix_list_23-6
}

ul.lst-kix_list_29-7 {
    list-style-type: none
}

ul.lst-kix_list_29-8 {
    list-style-type: none
}

.lst-kix_list_21-4>li {
    counter-increment: lst-ctn-kix_list_21-4
}

.lst-kix_list_13-3>li:before {
    content: "\0025cf  "
}

ul.lst-kix_list_18-0 {
    list-style-type: none
}

.lst-kix_list_13-5>li:before {
    content: "\0025aa  "
}

.lst-kix_list_12-5>li:before {
    content: "\0025aa  "
}

ul.lst-kix_list_18-8 {
    list-style-type: none
}

ul.lst-kix_list_18-7 {
    list-style-type: none
}

ul.lst-kix_list_18-6 {
    list-style-type: none
}

ul.lst-kix_list_18-5 {
    list-style-type: none
}

.lst-kix_list_12-7>li:before {
    content: "o  "
}

ul.lst-kix_list_18-4 {
    list-style-type: none
}

.lst-kix_list_33-6>li:before {
    content: "\0025cf  "
}

ul.lst-kix_list_18-3 {
    list-style-type: none
}

ul.lst-kix_list_18-2 {
    list-style-type: none
}

ol.lst-kix_list_21-1.start {
    counter-reset: lst-ctn-kix_list_21-1 0
}

ul.lst-kix_list_18-1 {
    list-style-type: none
}

.lst-kix_list_33-8>li:before {
    content: "\0025aa  "
}

.lst-kix_list_13-1>li:before {
    content: "o  "
}

ul.lst-kix_list_24-0 {
    list-style-type: none
}

.lst-kix_list_30-5>li:before {
    content: "\0025aa  "
}

ul.lst-kix_list_24-1 {
    list-style-type: none
}

.lst-kix_list_22-8>li {
    counter-increment: lst-ctn-kix_list_22-8
}

ul.lst-kix_list_24-2 {
    list-style-type: none
}

ul.lst-kix_list_24-3 {
    list-style-type: none
}

ol.lst-kix_list_21-3.start {
    counter-reset: lst-ctn-kix_list_21-3 0
}

ul.lst-kix_list_24-4 {
    list-style-type: none
}

ul.lst-kix_list_24-5 {
    list-style-type: none
}

.lst-kix_list_30-1>li:before {
    content: "o  "
}

.lst-kix_list_3-0>li:before {
    content: "\0025cf  "
}

.lst-kix_list_30-2>li:before {
    content: "\0025aa  "
}

ul.lst-kix_list_5-7 {
    list-style-type: none
}

ul.lst-kix_list_5-8 {
    list-style-type: none
}

ul.lst-kix_list_5-5 {
    list-style-type: none
}

ul.lst-kix_list_5-6 {
    list-style-type: none
}

.lst-kix_list_21-8>li:before {
    content: "" counter(lst-ctn-kix_list_21-8, lower-roman) ". "
}

.lst-kix_list_26-5>li:before {
    content: "\0025aa  "
}

ul.lst-kix_list_24-6 {
    list-style-type: none
}

ul.lst-kix_list_5-0 {
    list-style-type: none
}

ul.lst-kix_list_24-7 {
    list-style-type: none
}

.lst-kix_list_3-4>li:before {
    content: "o  "
}

ul.lst-kix_list_24-8 {
    list-style-type: none
}

ul.lst-kix_list_5-3 {
    list-style-type: none
}

.lst-kix_list_3-3>li:before {
    content: "\0025cf  "
}

ul.lst-kix_list_5-4 {
    list-style-type: none
}

.lst-kix_list_26-8>li:before {
    content: "\0025aa  "
}

ul.lst-kix_list_5-1 {
    list-style-type: none
}

.lst-kix_list_8-0>li:before {
    content: "\0025cf  "
}

.lst-kix_list_30-6>li:before {
    content: "\0025cf  "
}

ul.lst-kix_list_5-2 {
    list-style-type: none
}

.lst-kix_list_8-7>li:before {
    content: "o  "
}

.lst-kix_list_3-8>li:before {
    content: "\0025aa  "
}

.lst-kix_list_21-0>li:before {
    content: "" counter(lst-ctn-kix_list_21-0, decimal) ". "
}

.lst-kix_list_26-1>li:before {
    content: "o  "
}

.lst-kix_list_21-1>li:before {
    content: "" counter(lst-ctn-kix_list_21-1, lower-latin) ". "
}

.lst-kix_list_8-3>li:before {
    content: "\0025cf  "
}

ul.lst-kix_list_13-5 {
    list-style-type: none
}

ol.lst-kix_list_22-7 {
    list-style-type: none
}

ul.lst-kix_list_13-4 {
    list-style-type: none
}

ol.lst-kix_list_22-6 {
    list-style-type: none
}

ul.lst-kix_list_13-3 {
    list-style-type: none
}

ul.lst-kix_list_13-2 {
    list-style-type: none
}

ol.lst-kix_list_22-8 {
    list-style-type: none
}

ul.lst-kix_list_13-1 {
    list-style-type: none
}

.lst-kix_list_3-7>li:before {
    content: "o  "
}

ol.lst-kix_list_22-3 {
    list-style-type: none
}

ul.lst-kix_list_13-0 {
    list-style-type: none
}

.lst-kix_list_26-4>li:before {
    content: "o  "
}

ol.lst-kix_list_22-2 {
    list-style-type: none
}

.lst-kix_list_8-4>li:before {
    content: "o  "
}

ol.lst-kix_list_22-5 {
    list-style-type: none
}

ol.lst-kix_list_22-4 {
    list-style-type: none
}

ol.lst-kix_list_22-1 {
    list-style-type: none
}

ol.lst-kix_list_22-0 {
    list-style-type: none
}

ul.lst-kix_list_13-8 {
    list-style-type: none
}

.lst-kix_list_11-1>li:before {
    content: "o  "
}

ul.lst-kix_list_13-7 {
    list-style-type: none
}

ul.lst-kix_list_13-6 {
    list-style-type: none
}

.lst-kix_list_21-5>li:before {
    content: "" counter(lst-ctn-kix_list_21-5, lower-roman) ". "
}

.lst-kix_list_21-4>li:before {
    content: "" counter(lst-ctn-kix_list_21-4, lower-latin) ". "
}

.lst-kix_list_11-0>li:before {
    content: "\0025cf  "
}

.lst-kix_list_26-0>li:before {
    content: "\0025cf  "
}

.lst-kix_list_8-8>li:before {
    content: "\0025aa  "
}

.lst-kix_list_16-8>li:before {
    content: "\0025aa  "
}

.lst-kix_list_16-7>li:before {
    content: "o  "
}

.lst-kix_list_4-8>li:before {
    content: "\0025aa  "
}

.lst-kix_list_21-5>li {
    counter-increment: lst-ctn-kix_list_21-5
}

.lst-kix_list_4-7>li:before {
    content: "o  "
}

.lst-kix_list_17-0>li:before {
    content: "" counter(lst-ctn-kix_list_17-0, decimal) ". "
}

ul.lst-kix_list_4-8 {
    list-style-type: none
}

.lst-kix_list_16-0>li:before {
    content: "\0025aa  "
}

ul.lst-kix_list_4-6 {
    list-style-type: none
}

ul.lst-kix_list_4-7 {
    list-style-type: none
}

.lst-kix_list_21-0>li {
    counter-increment: lst-ctn-kix_list_21-0
}

.lst-kix_list_25-1>li:before {
    content: "o  "
}

ul.lst-kix_list_4-0 {
    list-style-type: none
}

.lst-kix_list_25-0>li:before {
    content: "\0025cf  "
}

.lst-kix_list_16-4>li:before {
    content: "o  "
}

ul.lst-kix_list_4-1 {
    list-style-type: none
}

.lst-kix_list_16-3>li:before {
    content: "\0025cf  "
}

ul.lst-kix_list_4-4 {
    list-style-type: none
}

ul.lst-kix_list_4-5 {
    list-style-type: none
}

ul.lst-kix_list_4-2 {
    list-style-type: none
}

ul.lst-kix_list_4-3 {
    list-style-type: none
}

ul.lst-kix_list_12-6 {
    list-style-type: none
}

ol.lst-kix_list_23-6 {
    list-style-type: none
}

ul.lst-kix_list_12-5 {
    list-style-type: none
}

ol.lst-kix_list_23-5 {
    list-style-type: none
}

.lst-kix_list_17-7>li:before {
    content: "o  "
}

ul.lst-kix_list_12-4 {
    list-style-type: none
}

ol.lst-kix_list_23-8 {
    list-style-type: none
}

ul.lst-kix_list_12-3 {
    list-style-type: none
}

ol.lst-kix_list_23-7 {
    list-style-type: none
}

ul.lst-kix_list_12-2 {
    list-style-type: none
}

ol.lst-kix_list_23-2 {
    list-style-type: none
}

ul.lst-kix_list_12-1 {
    list-style-type: none
}

ol.lst-kix_list_21-4.start {
    counter-reset: lst-ctn-kix_list_21-4 0
}

ol.lst-kix_list_23-1 {
    list-style-type: none
}

.lst-kix_list_17-8>li:before {
    content: "\0025aa  "
}

ul.lst-kix_list_12-0 {
    list-style-type: none
}

ol.lst-kix_list_23-4 {
    list-style-type: none
}

ol.lst-kix_list_23-3 {
    list-style-type: none
}

.lst-kix_list_17-3>li:before {
    content: "\0025cf  "
}

ol.lst-kix_list_23-0 {
    list-style-type: none
}

.lst-kix_list_17-4>li:before {
    content: "o  "
}

ul.lst-kix_list_12-8 {
    list-style-type: none
}

ul.lst-kix_list_12-7 {
    list-style-type: none
}

.lst-kix_list_7-0>li:before {
    content: "\0025cf  "
}

.lst-kix_list_22-5>li:before {
    content: "" counter(lst-ctn-kix_list_22-5, lower-roman) ". "
}

ul.lst-kix_list_26-0 {
    list-style-type: none
}

.lst-kix_list_2-4>li:before {
    content: "o  "
}

.lst-kix_list_2-8>li:before {
    content: "\0025aa  "
}

ul.lst-kix_list_26-1 {
    list-style-type: none
}

ul.lst-kix_list_26-2 {
    list-style-type: none
}

ul.lst-kix_list_26-3 {
    list-style-type: none
}

.lst-kix_list_21-2>li {
    counter-increment: lst-ctn-kix_list_21-2
}

.lst-kix_list_22-1>li:before {
    content: "" counter(lst-ctn-kix_list_22-1, lower-latin) ". "
}

.lst-kix_list_27-4>li:before {
    content: "o  "
}

.lst-kix_list_7-3>li:before {
    content: "\0025cf  "
}

ul.lst-kix_list_7-5 {
    list-style-type: none
}

.lst-kix_list_10-0>li:before {
    content: "\0025cf  "
}

ul.lst-kix_list_7-6 {
    list-style-type: none
}

.lst-kix_list_23-3>li {
    counter-increment: lst-ctn-kix_list_23-3
}

ul.lst-kix_list_7-3 {
    list-style-type: none
}

ol.lst-kix_list_21-7.start {
    counter-reset: lst-ctn-kix_list_21-7 0
}

ul.lst-kix_list_7-4 {
    list-style-type: none
}

.lst-kix_list_13-8>li:before {
    content: "\0025aa  "
}

.lst-kix_list_31-1>li:before {
    content: "o  "
}

.lst-kix_list_18-3>li:before {
    content: "\0025cf  "
}

.lst-kix_list_18-7>li:before {
    content: "o  "
}

ul.lst-kix_list_7-7 {
    list-style-type: none
}

ul.lst-kix_list_7-8 {
    list-style-type: none
}

ul.lst-kix_list_26-4 {
    list-style-type: none
}

ul.lst-kix_list_26-5 {
    list-style-type: none
}

ul.lst-kix_list_26-6 {
    list-style-type: none
}

ul.lst-kix_list_26-7 {
    list-style-type: none
}

ul.lst-kix_list_7-1 {
    list-style-type: none
}

ul.lst-kix_list_26-8 {
    list-style-type: none
}

ul.lst-kix_list_7-2 {
    list-style-type: none
}

.lst-kix_list_27-0>li:before {
    content: "\0025cf  "
}

ul.lst-kix_list_7-0 {
    list-style-type: none
}

.lst-kix_list_7-7>li:before {
    content: "o  "
}

.lst-kix_list_15-4>li:before {
    content: "o  "
}

.lst-kix_list_31-5>li:before {
    content: "\0025aa  "
}

.lst-kix_list_10-4>li:before {
    content: "o  "
}

.lst-kix_list_10-8>li:before {
    content: "\0025aa  "
}

.lst-kix_list_4-0>li:before {
    content: "\0025cf  "
}

ul.lst-kix_list_15-3 {
    list-style-type: none
}

ul.lst-kix_list_15-2 {
    list-style-type: none
}

.lst-kix_list_15-0>li:before {
    content: "\0025cf  "
}

ul.lst-kix_list_15-1 {
    list-style-type: none
}

.lst-kix_list_15-8>li:before {
    content: "\0025aa  "
}

ul.lst-kix_list_15-0 {
    list-style-type: none
}

.lst-kix_list_4-4>li:before {
    content: "o  "
}

.lst-kix_list_25-4>li:before {
    content: "o  "
}

ul.lst-kix_list_15-8 {
    list-style-type: none
}

ul.lst-kix_list_15-7 {
    list-style-type: none
}

ul.lst-kix_list_15-6 {
    list-style-type: none
}

.lst-kix_list_9-3>li:before {
    content: "\0025cf  "
}

ul.lst-kix_list_15-5 {
    list-style-type: none
}

ul.lst-kix_list_15-4 {
    list-style-type: none
}

.lst-kix_list_25-8>li:before {
    content: "\0025aa  "
}

ul.lst-kix_list_25-0 {
    list-style-type: none
}

ul.lst-kix_list_25-1 {
    list-style-type: none
}

ul.lst-kix_list_25-2 {
    list-style-type: none
}

.lst-kix_list_9-7>li:before {
    content: "o  "
}

.lst-kix_list_29-4>li:before {
    content: "o  "
}

.lst-kix_list_29-8>li:before {
    content: "\0025aa  "
}

ul.lst-kix_list_25-3 {
    list-style-type: none
}

ul.lst-kix_list_25-4 {
    list-style-type: none
}

ol.lst-kix_list_21-8.start {
    counter-reset: lst-ctn-kix_list_21-8 0
}

.lst-kix_list_32-5>li:before {
    content: "\0025aa  "
}

.lst-kix_list_11-4>li:before {
    content: "o  "
}

.lst-kix_list_22-5>li {
    counter-increment: lst-ctn-kix_list_22-5
}

.lst-kix_list_12-4>li:before {
    content: "o  "
}

ul.lst-kix_list_6-6 {
    list-style-type: none
}

ul.lst-kix_list_6-7 {
    list-style-type: none
}

ul.lst-kix_list_6-4 {
    list-style-type: none
}

.lst-kix_list_20-5>li:before {
    content: "\0025aa  "
}

ul.lst-kix_list_6-5 {
    list-style-type: none
}

ul.lst-kix_list_6-8 {
    list-style-type: none
}

.lst-kix_list_29-0>li:before {
    content: "\0025cf  "
}

.lst-kix_list_33-1>li:before {
    content: "o  "
}

.lst-kix_list_28-8>li:before {
    content: "\0025aa  "
}

ul.lst-kix_list_25-5 {
    list-style-type: none
}

.lst-kix_list_1-0>li:before {
    content: "\0025cf  "
}

ul.lst-kix_list_25-6 {
    list-style-type: none
}

.lst-kix_list_20-1>li:before {
    content: "o  "
}

ul.lst-kix_list_25-7 {
    list-style-type: none
}

ul.lst-kix_list_25-8 {
    list-style-type: none
}

ul.lst-kix_list_6-2 {
    list-style-type: none
}

.lst-kix_list_11-8>li:before {
    content: "\0025aa  "
}

ul.lst-kix_list_6-3 {
    list-style-type: none
}

ul.lst-kix_list_6-0 {
    list-style-type: none
}

.lst-kix_list_12-0>li:before {
    content: "\0025cf  "
}

ul.lst-kix_list_6-1 {
    list-style-type: none
}

.lst-kix_list_28-4>li:before {
    content: "o  "
}

.lst-kix_list_1-4>li:before {
    content: "o  "
}

.lst-kix_list_13-0>li:before {
    content: "\0025cf  "
}

.lst-kix_list_21-7>li {
    counter-increment: lst-ctn-kix_list_21-7
}

ul.lst-kix_list_14-4 {
    list-style-type: none
}

ul.lst-kix_list_14-3 {
    list-style-type: none
}

ul.lst-kix_list_14-2 {
    list-style-type: none
}

.lst-kix_list_13-4>li:before {
    content: "o  "
}

ul.lst-kix_list_14-1 {
    list-style-type: none
}

ul.lst-kix_list_14-0 {
    list-style-type: none
}

.lst-kix_list_33-5>li:before {
    content: "\0025aa  "
}

ul.lst-kix_list_14-8 {
    list-style-type: none
}

ul.lst-kix_list_14-7 {
    list-style-type: none
}

.lst-kix_list_2-0>li:before {
    content: "\0025cf  "
}

ul.lst-kix_list_14-6 {
    list-style-type: none
}

ul.lst-kix_list_14-5 {
    list-style-type: none
}

.lst-kix_list_28-0>li:before {
    content: "\0025cf  "
}

.lst-kix_list_1-8>li:before {
    content: "\0025aa  "
}

.lst-kix_list_27-8>li:before {
    content: "\0025aa  "
}

.lst-kix_list_12-8>li:before {
    content: "\0025aa  "
}

ul.lst-kix_list_20-2 {
    list-style-type: none
}

ul.lst-kix_list_20-3 {
    list-style-type: none
}

.lst-kix_list_19-0>li:before {
    content: "\0025cf  "
}

.lst-kix_list_19-1>li:before {
    content: "o  "
}

ul.lst-kix_list_20-4 {
    list-style-type: none
}

ul.lst-kix_list_20-5 {
    list-style-type: none
}

ul.lst-kix_list_20-6 {
    list-style-type: none
}

ul.lst-kix_list_1-0 {
    list-style-type: none
}

ul.lst-kix_list_20-7 {
    list-style-type: none
}

ul.lst-kix_list_20-8 {
    list-style-type: none
}

.lst-kix_list_23-8>li {
    counter-increment: lst-ctn-kix_list_23-8
}

.lst-kix_list_19-4>li:before {
    content: "o  "
}

.lst-kix_list_19-2>li:before {
    content: "\0025aa  "
}

.lst-kix_list_19-3>li:before {
    content: "\0025cf  "
}

ul.lst-kix_list_20-0 {
    list-style-type: none
}

ol.lst-kix_list_21-0.start {
    counter-reset: lst-ctn-kix_list_21-0 0
}

ul.lst-kix_list_20-1 {
    list-style-type: none
}

ul.lst-kix_list_1-3 {
    list-style-type: none
}

ul.lst-kix_list_1-4 {
    list-style-type: none
}

ul.lst-kix_list_1-1 {
    list-style-type: none
}

ul.lst-kix_list_1-2 {
    list-style-type: none
}

ul.lst-kix_list_1-7 {
    list-style-type: none
}

ul.lst-kix_list_1-8 {
    list-style-type: none
}

ul.lst-kix_list_1-5 {
    list-style-type: none
}

ul.lst-kix_list_1-6 {
    list-style-type: none
}

ul.lst-kix_list_32-1 {
    list-style-type: none
}

ul.lst-kix_list_32-2 {
    list-style-type: none
}

ul.lst-kix_list_32-3 {
    list-style-type: none
}

ul.lst-kix_list_32-4 {
    list-style-type: none
}

ul.lst-kix_list_32-5 {
    list-style-type: none
}

ul.lst-kix_list_32-6 {
    list-style-type: none
}

ul.lst-kix_list_32-7 {
    list-style-type: none
}

ul.lst-kix_list_32-8 {
    list-style-type: none
}

ol.lst-kix_list_22-4.start {
    counter-reset: lst-ctn-kix_list_22-4 0
}

.lst-kix_list_22-7>li {
    counter-increment: lst-ctn-kix_list_22-7
}

ul.lst-kix_list_32-0 {
    list-style-type: none
}

.lst-kix_list_19-8>li:before {
    content: "\0025aa  "
}

.lst-kix_list_19-5>li:before {
    content: "\0025aa  "
}

.lst-kix_list_19-6>li:before {
    content: "\0025cf  "
}

.lst-kix_list_19-7>li:before {
    content: "o  "
}

ol.lst-kix_list_23-8.start {
    counter-reset: lst-ctn-kix_list_23-8 0
}

ol.lst-kix_list_21-5.start {
    counter-reset: lst-ctn-kix_list_21-5 0
}

.lst-kix_list_22-0>li {
    counter-increment: lst-ctn-kix_list_22-0
}

.lst-kix_list_21-6>li {
    counter-increment: lst-ctn-kix_list_21-6
}

ul.lst-kix_list_31-2 {
    list-style-type: none
}

ul.lst-kix_list_31-3 {
    list-style-type: none
}

ul.lst-kix_list_31-4 {
    list-style-type: none
}

ul.lst-kix_list_31-5 {
    list-style-type: none
}

ul.lst-kix_list_31-6 {
    list-style-type: none
}

ul.lst-kix_list_31-7 {
    list-style-type: none
}

.lst-kix_list_18-0>li:before {
    content: "\0025cf  "
}

ul.lst-kix_list_31-8 {
    list-style-type: none
}

.lst-kix_list_18-1>li:before {
    content: "o  "
}

.lst-kix_list_18-2>li:before {
    content: "\0025aa  "
}

li.li-bullet-2:before {
    margin-left: -22.5pt;
    white-space: nowrap;
    display: inline-block;
    min-width: 22.5pt
}

ul.lst-kix_list_31-0 {
    list-style-type: none
}

ul.lst-kix_list_31-1 {
    list-style-type: none
}

.lst-kix_list_23-4>li {
    counter-increment: lst-ctn-kix_list_23-4
}

ol.lst-kix_list_23-1.start {
    counter-reset: lst-ctn-kix_list_23-1 0
}

.lst-kix_list_23-1>li {
    counter-increment: lst-ctn-kix_list_23-1
}

.lst-kix_list_2-7>li:before {
    content: "o  "
}

.lst-kix_list_27-1>li:before {
    content: "\0025cf  "
}

.lst-kix_list_2-5>li:before {
    content: "\0025aa  "
}

.lst-kix_list_27-3>li:before {
    content: "\0025cf  "
}

ol.lst-kix_list_17-0.start {
    counter-reset: lst-ctn-kix_list_17-0 0
}

.lst-kix_list_27-5>li:before {
    content: "\0025aa  "
}

.lst-kix_list_22-3>li {
    counter-increment: lst-ctn-kix_list_22-3
}

.lst-kix_list_18-6>li:before {
    content: "\0025cf  "
}

ul.lst-kix_list_3-7 {
    list-style-type: none
}

ul.lst-kix_list_3-8 {
    list-style-type: none
}

.lst-kix_list_10-1>li:before {
    content: "\0025cf  "
}

.lst-kix_list_21-3>li {
    counter-increment: lst-ctn-kix_list_21-3
}

.lst-kix_list_18-4>li:before {
    content: "o  "
}

.lst-kix_list_18-8>li:before {
    content: "\0025aa  "
}

ul.lst-kix_list_3-1 {
    list-style-type: none
}

ul.lst-kix_list_3-2 {
    list-style-type: none
}

ul.lst-kix_list_3-0 {
    list-style-type: none
}

ul.lst-kix_list_3-5 {
    list-style-type: none
}

ul.lst-kix_list_3-6 {
    list-style-type: none
}

ul.lst-kix_list_3-3 {
    list-style-type: none
}

ul.lst-kix_list_3-4 {
    list-style-type: none
}

.lst-kix_list_10-7>li:before {
    content: "o  "
}

.lst-kix_list_10-5>li:before {
    content: "\0025aa  "
}

li.li-bullet-1:before {
    margin-left: -22.5pt;
    white-space: nowrap;
    display: inline-block;
    min-width: 22.5pt
}

.lst-kix_list_10-3>li:before {
    content: "\0025cf  "
}

ul.lst-kix_list_11-7 {
    list-style-type: none
}

ul.lst-kix_list_11-6 {
    list-style-type: none
}

ul.lst-kix_list_11-5 {
    list-style-type: none
}

ul.lst-kix_list_11-4 {
    list-style-type: none
}

ul.lst-kix_list_11-3 {
    list-style-type: none
}

ul.lst-kix_list_11-2 {
    list-style-type: none
}

ul.lst-kix_list_11-1 {
    list-style-type: none
}

ul.lst-kix_list_11-0 {
    list-style-type: none
}

.lst-kix_list_9-2>li:before {
    content: "\0025aa  "
}

ul.lst-kix_list_11-8 {
    list-style-type: none
}

.lst-kix_list_20-8>li:before {
    content: "\0025aa  "
}

.lst-kix_list_9-0>li:before {
    content: "\0025cf  "
}

.lst-kix_list_29-7>li:before {
    content: "o  "
}

.lst-kix_list_29-5>li:before {
    content: "\0025aa  "
}

.lst-kix_list_20-0>li:before {
    content: "\0025cf  "
}

.lst-kix_list_9-6>li:before {
    content: "\0025cf  "
}

.lst-kix_list_9-4>li:before {
    content: "o  "
}

.lst-kix_list_23-5>li {
    counter-increment: lst-ctn-kix_list_23-5
}

.lst-kix_list_11-3>li:before {
    content: "\0025cf  "
}

.lst-kix_list_20-6>li:before {
    content: "\0025cf  "
}

.lst-kix_list_23-0>li {
    counter-increment: lst-ctn-kix_list_23-0
}

ul.lst-kix_list_2-8 {
    list-style-type: none
}

.lst-kix_list_29-1>li:before {
    content: "\0025cf  "
}

.lst-kix_list_20-4>li:before {
    content: "o  "
}

.lst-kix_list_11-5>li:before {
    content: "\0025aa  "
}

ol.lst-kix_list_22-7.start {
    counter-reset: lst-ctn-kix_list_22-7 0
}

ul.lst-kix_list_2-2 {
    list-style-type: none
}

.lst-kix_list_29-3>li:before {
    content: "\0025cf  "
}

.lst-kix_list_20-2>li:before {
    content: "\0025aa  "
}

ol.lst-kix_list_21-2.start {
    counter-reset: lst-ctn-kix_list_21-2 0
}

ul.lst-kix_list_2-3 {
    list-style-type: none
}

ul.lst-kix_list_2-0 {
    list-style-type: none
}

ul.lst-kix_list_2-1 {
    list-style-type: none
}

.lst-kix_list_9-8>li:before {
    content: "\0025aa  "
}

ul.lst-kix_list_2-6 {
    list-style-type: none
}

.lst-kix_list_1-1>li:before {
    content: "o  "
}

ul.lst-kix_list_2-7 {
    list-style-type: none
}

.lst-kix_list_11-7>li:before {
    content: "o  "
}

ul.lst-kix_list_2-4 {
    list-style-type: none
}

ul.lst-kix_list_2-5 {
    list-style-type: none
}

ul.lst-kix_list_10-0 {
    list-style-type: none
}

ul.lst-kix_list_33-0 {
    list-style-type: none
}

ul.lst-kix_list_33-1 {
    list-style-type: none
}

.lst-kix_list_1-3>li:before {
    content: "\0025cf  "
}

ul.lst-kix_list_33-2 {
    list-style-type: none
}

ul.lst-kix_list_33-3 {
    list-style-type: none
}

ul.lst-kix_list_33-4 {
    list-style-type: none
}

ul.lst-kix_list_33-5 {
    list-style-type: none
}

ul.lst-kix_list_33-6 {
    list-style-type: none
}

.lst-kix_list_28-3>li:before {
    content: "\0025cf  "
}

.lst-kix_list_28-7>li:before {
    content: "o  "
}

ul.lst-kix_list_33-7 {
    list-style-type: none
}

ul.lst-kix_list_10-8 {
    list-style-type: none
}

ol.lst-kix_list_21-8 {
    list-style-type: none
}

ul.lst-kix_list_10-7 {
    list-style-type: none
}

.lst-kix_list_1-7>li:before {
    content: "o  "
}

ol.lst-kix_list_21-7 {
    list-style-type: none
}

ul.lst-kix_list_10-6 {
    list-style-type: none
}

ul.lst-kix_list_10-5 {
    list-style-type: none
}

ul.lst-kix_list_10-4 {
    list-style-type: none
}

ol.lst-kix_list_21-4 {
    list-style-type: none
}

ul.lst-kix_list_10-3 {
    list-style-type: none
}

.lst-kix_list_1-5>li:before {
    content: "\0025aa  "
}

ol.lst-kix_list_21-3 {
    list-style-type: none
}

ul.lst-kix_list_10-2 {
    list-style-type: none
}

.lst-kix_list_28-5>li:before {
    content: "\0025aa  "
}

ol.lst-kix_list_21-6 {
    list-style-type: none
}

ul.lst-kix_list_10-1 {
    list-style-type: none
}

ol.lst-kix_list_21-5 {
    list-style-type: none
}

ol.lst-kix_list_21-0 {
    list-style-type: none
}

ol.lst-kix_list_22-6.start {
    counter-reset: lst-ctn-kix_list_22-6 0
}

ol.lst-kix_list_21-2 {
    list-style-type: none
}

ol.lst-kix_list_21-1 {
    list-style-type: none
}

.lst-kix_list_2-1>li:before {
    content: "o  "
}

.lst-kix_list_27-7>li:before {
    content: "o  "
}

ul.lst-kix_list_33-8 {
    list-style-type: none
}

.lst-kix_list_2-3>li:before {
    content: "\0025cf  "
}

.lst-kix_list_28-1>li:before {
    content: "o  "
}

.lst-kix_list_30-3>li:before {
    content: "\0025cf  "
}

.lst-kix_list_30-4>li:before {
    content: "o  "
}

.lst-kix_list_30-8>li:before {
    content: "\0025aa  "
}

.lst-kix_list_3-1>li:before {
    content: "o  "
}

.lst-kix_list_3-2>li:before {
    content: "\0025aa  "
}

.lst-kix_list_26-6>li:before {
    content: "\0025cf  "
}

.lst-kix_list_26-7>li:before {
    content: "o  "
}

.lst-kix_list_8-1>li:before {
    content: "\0025cf  "
}

.lst-kix_list_8-2>li:before {
    content: "\0025aa  "
}

.lst-kix_list_3-5>li:before {
    content: "\0025aa  "
}

.lst-kix_list_30-7>li:before {
    content: "o  "
}

.lst-kix_list_21-2>li:before {
    content: "" counter(lst-ctn-kix_list_21-2, lower-roman) ". "
}

.lst-kix_list_8-5>li:before {
    content: "\0025aa  "
}

.lst-kix_list_8-6>li:before {
    content: "\0025cf  "
}

.lst-kix_list_26-2>li:before {
    content: "\0025aa  "
}

.lst-kix_list_26-3>li:before {
    content: "\0025cf  "
}

.lst-kix_list_3-6>li:before {
    content: "\0025cf  "
}

.lst-kix_list_21-6>li:before {
    content: "" counter(lst-ctn-kix_list_21-6, decimal) ". "
}

.lst-kix_list_21-7>li:before {
    content: "" counter(lst-ctn-kix_list_21-7, lower-latin) ". "
}

.lst-kix_list_11-2>li:before {
    content: "\0025aa  "
}

ol.lst-kix_list_22-1.start {
    counter-reset: lst-ctn-kix_list_22-1 0
}

.lst-kix_list_21-3>li:before {
    content: "" counter(lst-ctn-kix_list_21-3, decimal) ". "
}

.lst-kix_list_16-6>li:before {
    content: "\0025cf  "
}

.lst-kix_list_25-2>li:before {
    content: "\0025aa  "
}

.lst-kix_list_17-1>li:before {
    content: "o  "
}

.lst-kix_list_25-3>li:before {
    content: "\0025cf  "
}

.lst-kix_list_22-6>li {
    counter-increment: lst-ctn-kix_list_22-6
}

.lst-kix_list_16-1>li:before {
    content: "o  "
}

.lst-kix_list_16-2>li:before {
    content: "\0025aa  "
}

.lst-kix_list_16-5>li:before {
    content: "\0025aa  "
}

ol.lst-kix_list_22-2.start {
    counter-reset: lst-ctn-kix_list_22-2 0
}

.lst-kix_list_23-7>li {
    counter-increment: lst-ctn-kix_list_23-7
}

.lst-kix_list_17-2>li:before {
    content: "\0025aa  "
}

.lst-kix_list_30-0>li:before {
    content: "\0025cf  "
}

.lst-kix_list_17-6>li:before {
    content: "\0025cf  "
}

.lst-kix_list_17-5>li:before {
    content: "\0025aa  "
}

.lst-kix_list_2-6>li:before {
    content: "\0025cf  "
}

.lst-kix_list_27-2>li:before {
    content: "\0025aa  "
}

.lst-kix_list_22-3>li:before {
    content: "" counter(lst-ctn-kix_list_22-3, decimal) ". "
}

.lst-kix_list_7-1>li:before {
    content: "o  "
}

.lst-kix_list_7-5>li:before {
    content: "\0025aa  "
}

.lst-kix_list_27-6>li:before {
    content: "\0025cf  "
}

.lst-kix_list_22-7>li:before {
    content: "" counter(lst-ctn-kix_list_22-7, lower-latin) ". "
}

ol.lst-kix_list_23-3.start {
    counter-reset: lst-ctn-kix_list_23-3 0
}

.lst-kix_list_23-2>li {
    counter-increment: lst-ctn-kix_list_23-2
}

.lst-kix_list_18-5>li:before {
    content: "\0025aa  "
}

.lst-kix_list_13-6>li:before {
    content: "\0025cf  "
}

ul.lst-kix_list_30-3 {
    list-style-type: none
}

.lst-kix_list_31-7>li:before {
    content: "o  "
}

ul.lst-kix_list_30-4 {
    list-style-type: none
}

ul.lst-kix_list_30-5 {
    list-style-type: none
}

.lst-kix_list_15-6>li:before {
    content: "\0025cf  "
}

ul.lst-kix_list_30-6 {
    list-style-type: none
}

ul.lst-kix_list_30-7 {
    list-style-type: none
}

ul.lst-kix_list_30-8 {
    list-style-type: none
}

.lst-kix_list_22-4>li {
    counter-increment: lst-ctn-kix_list_22-4
}

.lst-kix_list_31-3>li:before {
    content: "\0025cf  "
}

ul.lst-kix_list_30-0 {
    list-style-type: none
}

.lst-kix_list_10-2>li:before {
    content: "\0025aa  "
}

ul.lst-kix_list_30-1 {
    list-style-type: none
}

ul.lst-kix_list_30-2 {
    list-style-type: none
}

.lst-kix_list_20-7>li:before {
    content: "o  "
}

.lst-kix_list_4-2>li:before {
    content: "\0025aa  "
}

.lst-kix_list_4-6>li:before {
    content: "\0025cf  "
}

ol.lst-kix_list_23-6.start {
    counter-reset: lst-ctn-kix_list_23-6 0
}

.lst-kix_list_15-2>li:before {
    content: "\0025aa  "
}

.lst-kix_list_25-6>li:before {
    content: "\0025cf  "
}

.lst-kix_list_10-6>li:before {
    content: "\0025cf  "
}

.lst-kix_list_9-1>li:before {
    content: "o  "
}

.lst-kix_list_9-5>li:before {
    content: "\0025aa  "
}

.lst-kix_list_29-6>li:before {
    content: "\0025cf  "
}

ol.lst-kix_list_22-0.start {
    counter-reset: lst-ctn-kix_list_22-0 0
}

.lst-kix_list_33-3>li:before {
    content: "\0025cf  "
}

.lst-kix_list_12-2>li:before {
    content: "\0025aa  "
}

.lst-kix_list_11-6>li:before {
    content: "\0025cf  "
}

.lst-kix_list_32-7>li:before {
    content: "o  "
}

.lst-kix_list_20-3>li:before {
    content: "\0025cf  "
}

.lst-kix_list_1-2>li:before {
    content: "\0025aa  "
}

.lst-kix_list_29-2>li:before {
    content: "\0025aa  "
}

ol.lst-kix_list_23-5.start {
    counter-reset: lst-ctn-kix_list_23-5 0
}

.lst-kix_list_21-1>li {
    counter-increment: lst-ctn-kix_list_21-1
}

.lst-kix_list_28-6>li:before {
    content: "\0025cf  "
}

.lst-kix_list_1-6>li:before {
    content: "\0025cf  "
}

li.li-bullet-0:before {
    margin-left: -18pt;
    white-space: nowrap;
    display: inline-block;
    min-width: 18pt
}

.lst-kix_list_33-7>li:before {
    content: "o  "
}

.lst-kix_list_12-6>li:before {
    content: "\0025cf  "
}

ol.lst-kix_list_23-4.start {
    counter-reset: lst-ctn-kix_list_23-4 0
}

.lst-kix_list_2-2>li:before {
    content: "\0025aa  "
}

.lst-kix_list_13-2>li:before {
    content: "\0025aa  "
}

.lst-kix_list_28-2>li:before {
    content: "\0025aa  "
}

ol {
    margin: 0;
    padding: 0
}

table td,
table th {
    padding: 0
}

.c10 {
    border-right-style: solid;
    padding: 0pt 5.4pt 0pt 5.4pt;
    border-bottom-color: #FFFFFF;
    border-top-width: 1pt;
    border-right-width: 1pt;
    border-left-color: #FFFFFF;
    vertical-align: middle;
    border-right-color: #FFFFFF;
    border-left-width: 1pt;
    border-top-style: solid;
    border-left-style: solid;
    border-bottom-width: 1pt;
    width: 155.8pt;
    border-top-color: #FFFFFF;
    border-bottom-style: solid
}

.c13 {
    /* border-right-style: solid;
    padding: 0pt 5.4pt 0pt 5.4pt;
    border-bottom-color: #FFFFFF;
    border-top-width: 1pt;
    border-right-width: 1pt;
    border-left-color: #FFFFFF;
    vertical-align: middle;
    border-right-color: #FFFFFF;
    border-left-width: 1pt;
    border-top-style: solid;
    border-left-style: solid;
    border-bottom-width: 1pt;
    width: 54pt;
    border-top-color: #FFFFFF;
    border-bottom-style: solid */
}

.c33 {
    border-right-style: solid;
    padding: 0pt 5.4pt 0pt 5.4pt;
    border-bottom-color: #FFFFFF;
    border-top-width: 1pt;
    border-right-width: 1pt;
    border-left-color: #FFFFFF;
    vertical-align: top;
    border-right-color: #FFFFFF;
    border-left-width: 1pt;
    border-top-style: solid;
    border-left-style: solid;
    border-bottom-width: 1pt;
    width: 54pt;
    border-top-color: #FFFFFF;
    border-bottom-style: solid
}

.c18 {
    border-right-style: solid;
    padding: 0pt 5.4pt 0pt 5.4pt;
    border-bottom-color: #FFFFFF;
    border-top-width: 1pt;
    border-right-width: 1pt;
    border-left-color: #FFFFFF;
    vertical-align: middle;
    border-right-color: #FFFFFF;
    border-left-width: 1pt;
    border-top-style: solid;
    border-left-style: solid;
    border-bottom-width: 1pt;
    width: 266.9pt;
    border-top-color: #FFFFFF;
    border-bottom-style: solid
}

.c29 {
    border-right-style: solid;
    padding: 0pt 5.4pt 0pt 5.4pt;
    border-bottom-color: #FFFFFF;
    border-top-width: 1pt;
    border-right-width: 1pt;
    border-left-color: #FFFFFF;
    vertical-align: top;
    border-right-color: #FFFFFF;
    border-left-width: 1pt;
    border-top-style: solid;
    border-left-style: solid;
    border-bottom-width: 1pt;
    width: 155.8pt;
    border-top-color: #FFFFFF;
    border-bottom-style: solid
}

.c31 {
    border-right-style: solid;
    padding: 0pt 5.4pt 0pt 5.4pt;
    border-bottom-color: #FFFFFF;
    border-top-width: 1pt;
    border-right-width: 1pt;
    border-left-color: #FFFFFF;
    vertical-align: top;
    border-right-color: #FFFFFF;
    border-left-width: 1pt;
    border-top-style: solid;
    border-left-style: solid;
    border-bottom-width: 1pt;
    width: 266.9pt;
    border-top-color: #FFFFFF;
    border-bottom-style: solid
}

.c14 {
    -webkit-text-decoration-skip: none;
    color: #0563c1;
    font-weight: 400;
    text-decoration: underline;
    vertical-align: baseline;
    text-decoration-skip-ink: none;
    font-size: 11pt;
      font-style: normal
}

.c8 {
    margin-left: 54pt;
    padding-top: 0pt;
    padding-left: 4.5pt;
    padding-bottom: 0pt;
    line-height: 1.0791666666666666;
    orphans: 2;
    widows: 2;
    text-align: left
}

.c1 {
    margin-left: 36pt;
    padding-top: 0pt;
    padding-left: 0pt;
    padding-bottom: 0pt;
    line-height: 1.0791666666666666;
    orphans: 2;
    widows: 2;
    text-align: left
}

.c24 {
    -webkit-text-decoration-skip: none;
    color: #FFFFFF;
    text-decoration: underline;
    vertical-align: baseline;
    text-decoration-skip-ink: none;
    font-size: 11pt;
    font-family: "Calibri"
}

.c4 {
    color: #FFFFFF;
    font-weight: 400;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 11pt;
      font-style: normal
}

.c6 {
    margin-left: 18pt;
    padding-top: 0pt;
    padding-bottom: 8pt;
    line-height: 1.0;
    orphans: 2;
    widows: 2;
    text-align: left
}

.c26 {
    color: #FFFFFF;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 20pt;
      font-style: normal
}

.c21 {
    color: #FFFFFF;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 14pt;
      font-style: normal
}

.c12 {
    color: #FFFFFF;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 11pt;
      font-style: normal
}

.c15 {
    padding-top: 0pt;
    padding-bottom: 0pt;
    line-height: 1.0;
    orphans: 2;
    widows: 2;
    text-align: center
}

.c32 {
    color: #FFFFFF;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 12pt;
      font-style: normal
}

.c5 {
    color: #FFFFFF;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 16pt;
      font-style: normal
}

.c0 {
    padding-top: 0pt;
    padding-bottom: 8pt;
    line-height: 1.0791666666666666;
    orphans: 2;
    widows: 2;
    text-align: left
}

.c9 {
    padding-top: 0pt;
    padding-bottom: 0pt;
    line-height: 1.0;
    orphans: 2;
    widows: 2;
    text-align: left
}

.c25 {
    text-decoration-skip-ink: none;
    -webkit-text-decoration-skip: none;
    color: #0563c1;
    text-decoration: underline
}

.c30 {
    border-spacing: 0;
    border-collapse: collapse;
    margin-right: auto
}

.c27 {
    text-decoration-skip-ink: none;
    -webkit-text-decoration-skip: none;
    text-decoration: underline
}

.c16 {
    max-width: 468pt;
    padding: 72pt 72pt 72pt 72pt
}

.c17 {
    margin-left: 36pt;
    padding-left: 0pt
}

.c7 {
    color: inherit;
    text-decoration: inherit
}

.c2 {
    padding: 0;
    margin: 0
}

.c28 {
    font-weight: 400;
    font-style: normal
}

.c20 {
    margin-left: 54pt;
    padding-left: 4.5pt
}

.c3 {
    font-weight: 700
}

.c23 {
    height: 11pt
}

.c19 {
    font-style: italic
}

.c22 {
    font-size: 16pt
}

.c11 {
    height: 0pt
}

.title {
    padding-top: 24pt;
    color: #FFFFFF;
    font-weight: 700;
    font-size: 36pt;
    padding-bottom: 6pt;
      line-height: 1.0791666666666666;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left
}

.subtitle {
    padding-top: 18pt;
    color: #666666;
    font-size: 24pt;
    padding-bottom: 4pt;
    font-family: "Georgia";
    line-height: 1.0791666666666666;
    page-break-after: avoid;
    font-style: italic;
    orphans: 2;
    widows: 2;
    text-align: left
}

li {
    color: #FFFFFF;
    font-size: 11pt;
    font-family: "Calibri"
}

p {
    color: #FFFFFF;
}

h1 {
    color: #FFFFFF;
}

h2 {
    color: #FFFFFF;
}

h3 {
    color: #FFFFFF;
}

h4 {
    color: #FFFFFF;
}

h5 {
    color: #FFFFFF;
}

h6 {
    color: #FFFFFF;
}