.App {
  text-align: center;
  background-color: #181818;
  flex: 1;
}

/* .App-logo {
  height: 40vmin;
  pointer-events: none;
} */

@media (prefers-reduced-motion: no-preference) {
  /* .App-logo {
    animation: App-logo-spin infinite 20s linear;
  } */
}

.copyRight {
  color: #939393;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
}

.socialFrame {
  width: 32px;
  /* height: 32px; */
  display: flex;
  align-items: center;
  flex: none;
  justify-content: center;
  flex-grow: 0;
}

.ironSocialFrame {
  width: 32px;
  /* height: 32px; */
  display: flex;
  align-items: center;
  flex: none;
  justify-content: center;
  flex-grow: 0;
}

.socialIcon {
  background-color: #3b3b3b;
  width: 65px;
  height: 65px;
  display: flex;
  align-items: center;
  flex: none;
  border-radius: 22px;
  justify-content: center;
  flex-grow: 0;
}

.socialsMenu {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  gap: 50px;
}

.footerMenu {
  display: flex;
  /* flex-direction: row; */
  /* align-items: flex-start; */
  /* padding: 0px; */
  gap: 54px;
  /* height: 500px; */
}

.siteFooter {
  color: #fff;
  background-color: #272727;
  /* height: 200px; */
  max-height: fit-content;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* padding: 50px 0px; */
  gap: 30px;
}

body {
  background-color: #181818;
  color: "#FFFFFF";
}

a {
  text-decoration: none;
}

.tile {
  background-color: #272727;
  flex: 1;
}

.topHeaderLink {
  color: #fff;
  background: none;
  stroke: none;
  border: none;
  font-size: 14px;
}

.headerMenu {
  display: flex;
  flex: none;
  flex-direction: row;
  flex-grow: 0;
  order: 1;
  align-items: center;
  padding: 0px;
  gap: 40px;
  color: #fff;
}

.headerLogo {
  color: #fff;
  max-width: fit-content;
  /* height: 40vmin; */
  height: 80px;
  display: flex;
  flex-direction: row;
  order: 0;
  align-items: center;
  justify-content: center;
  padding: 0px;
  gap: 10px;
}
.logoIcon {
  background-blend-mode: soft-light;
  height: 60px;
  position: relative;
  object-position: center;
}
/* .logoIcon {
  /* background-color: rgb(229, 109, 109);
  scale: 15%;
  height: 40vmin;
  pointer-events: none; */
/* } */

.headerDiv {
  flex: 1;
  justify-content: space-between;
  align-items: center;
  display: flex;
  padding: 10px 80px;
  flex-direction: row;
  color: #fff;
  height: 80px;
  background-color: #272727;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
