.lst-kix_list_2-6 > li:before {
  content: "\0025cf  ";
}

.lst-kix_list_2-7 > li:before {
  content: "o  ";
}

ul.lst-kix_list_1-0 {
  list-style-type: none;
}

.lst-kix_list_2-4 > li:before {
  content: "o  ";
}

.lst-kix_list_2-5 > li:before {
  content: "\0025aa  ";
}

.lst-kix_list_2-8 > li:before {
  content: "\0025aa  ";
}

.lst-kix_list_3-0 > li:before {
  content: "\0025cf  ";
}

.lst-kix_list_3-1 > li:before {
  content: "o  ";
}

.lst-kix_list_3-2 > li:before {
  content: "\0025aa  ";
}

ul.lst-kix_list_3-7 {
  list-style-type: none;
}

ul.lst-kix_list_3-8 {
  list-style-type: none;
}

ul.lst-kix_list_1-3 {
  list-style-type: none;
}

ul.lst-kix_list_3-1 {
  list-style-type: none;
}

.lst-kix_list_3-5 > li:before {
  content: "\0025aa  ";
}

ul.lst-kix_list_1-4 {
  list-style-type: none;
}

ul.lst-kix_list_3-2 {
  list-style-type: none;
}

ul.lst-kix_list_1-1 {
  list-style-type: none;
}

.lst-kix_list_3-4 > li:before {
  content: "o  ";
}

ul.lst-kix_list_1-2 {
  list-style-type: none;
}

ul.lst-kix_list_3-0 {
  list-style-type: none;
}

ul.lst-kix_list_1-7 {
  list-style-type: none;
}

.lst-kix_list_3-3 > li:before {
  content: "\0025cf  ";
}

ul.lst-kix_list_3-5 {
  list-style-type: none;
}

ul.lst-kix_list_1-8 {
  list-style-type: none;
}

ul.lst-kix_list_3-6 {
  list-style-type: none;
}

ul.lst-kix_list_1-5 {
  list-style-type: none;
}

ul.lst-kix_list_3-3 {
  list-style-type: none;
}

ul.lst-kix_list_1-6 {
  list-style-type: none;
}

ul.lst-kix_list_3-4 {
  list-style-type: none;
}

.lst-kix_list_3-8 > li:before {
  content: "\0025aa  ";
}

.lst-kix_list_4-0 > li:before {
  content: "\0025cf  ";
}

.lst-kix_list_4-1 > li:before {
  content: "o  ";
}

.lst-kix_list_3-6 > li:before {
  content: "\0025cf  ";
}

.lst-kix_list_3-7 > li:before {
  content: "o  ";
}

.lst-kix_list_4-4 > li:before {
  content: "o  ";
}

.lst-kix_list_4-3 > li:before {
  content: "\0025cf  ";
}

.lst-kix_list_4-5 > li:before {
  content: "\0025aa  ";
}

.lst-kix_list_4-2 > li:before {
  content: "\0025aa  ";
}

.lst-kix_list_4-6 > li:before {
  content: "\0025cf  ";
}

.lst-kix_list_4-8 > li:before {
  content: "\0025aa  ";
}

.lst-kix_list_4-7 > li:before {
  content: "o  ";
}

ul.lst-kix_list_4-8 {
  list-style-type: none;
}

ul.lst-kix_list_4-6 {
  list-style-type: none;
}

ul.lst-kix_list_2-8 {
  list-style-type: none;
}

ul.lst-kix_list_4-7 {
  list-style-type: none;
}

ul.lst-kix_list_4-0 {
  list-style-type: none;
}

ul.lst-kix_list_2-2 {
  list-style-type: none;
}

ul.lst-kix_list_4-1 {
  list-style-type: none;
}

.lst-kix_list_1-0 > li:before {
  content: "\0025cf  ";
}

ul.lst-kix_list_2-3 {
  list-style-type: none;
}

ul.lst-kix_list_2-0 {
  list-style-type: none;
}

ul.lst-kix_list_2-1 {
  list-style-type: none;
}

ul.lst-kix_list_4-4 {
  list-style-type: none;
}

ul.lst-kix_list_2-6 {
  list-style-type: none;
}

ul.lst-kix_list_4-5 {
  list-style-type: none;
}

.lst-kix_list_1-1 > li:before {
  content: "o  ";
}

.lst-kix_list_1-2 > li:before {
  content: "\0025aa  ";
}

ul.lst-kix_list_2-7 {
  list-style-type: none;
}

ul.lst-kix_list_4-2 {
  list-style-type: none;
}

ul.lst-kix_list_2-4 {
  list-style-type: none;
}

ul.lst-kix_list_4-3 {
  list-style-type: none;
}

ul.lst-kix_list_2-5 {
  list-style-type: none;
}

.lst-kix_list_1-3 > li:before {
  content: "\0025cf  ";
}

.lst-kix_list_1-4 > li:before {
  content: "o  ";
}

.lst-kix_list_1-7 > li:before {
  content: "o  ";
}

.lst-kix_list_1-5 > li:before {
  content: "\0025aa  ";
}

.lst-kix_list_1-6 > li:before {
  content: "\0025cf  ";
}

li.li-bullet-0:before {
  margin-left: -18pt;
  white-space: nowrap;
  display: inline-block;
  min-width: 18pt;
}

.lst-kix_list_2-0 > li:before {
  content: "\0025cf  ";
}

.lst-kix_list_2-1 > li:before {
  content: "o  ";
}

.lst-kix_list_1-8 > li:before {
  content: "\0025aa  ";
}

.lst-kix_list_2-2 > li:before {
  content: "\0025aa  ";
}

.lst-kix_list_2-3 > li:before {
  content: "\0025cf  ";
}

ol {
  margin: 0;
  padding: 0;
}

table td,
table th {
  padding: 0;
}

.c0 {
  margin-left: 36pt;
  padding-top: 0pt;
  padding-left: 0pt;
  padding-bottom: 0pt;
  line-height: 1;
  orphans: 2;
  widows: 2;
  text-align: left;
}

.c11 {
  margin-left: 36pt;
  padding-top: 0pt;
  padding-left: 0pt;
  padding-bottom: 8pt;
  line-height: 1;
  orphans: 2;
  widows: 2;
  text-align: left;
}

.c9 {
  margin-left: 36pt;
  padding-top: 0pt;
  padding-left: 0pt;
  padding-bottom: 0pt;
  line-height: 1.0791666666666666;
  orphans: 2;
  widows: 2;
  text-align: left;
}

.c1 {
  color: #ffffff;
  font-weight: 400;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 11pt;
  font-style: normal;
}

.c3 {
  padding-top: 0pt;
  padding-bottom: 8pt;
  line-height: 1.0791666666666666;
  orphans: 2;
  widows: 2;
  text-align: left;
}

.c5 {
  -webkit-text-decoration-skip: none;
  color: #0563c1;
  font-weight: 400;
  text-decoration: underline;
  text-decoration-skip-ink: none;
}

.c15 {
  color: #ffffff;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 10pt;
  font-style: normal;
}

.c8 {
  color: #ffffff;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 14pt;
  font-style: normal;
}

.c17 {
  color: #ffffff;
  text-decoration: none;
  vertical-align: baseline;
  font-style: normal;
}

.c10 {
  max-width: 468pt;
  padding: 72pt 72pt 72pt 72pt;
}

.c13 {
  margin-left: 36pt;
  padding-left: 0pt;
}

.c16 {
  padding: 0;
  margin: 0;
}

.c6 {
  color: inherit;
  text-decoration: inherit;
}

.c14 {
  font-size: 14pt;
  font-weight: 700;
}

.c7 {
  font-weight: 400;
}

.c2 {
  font-weight: 700;
}

.c12 {
  font-size: 12pt;
}

.c4 {
  height: 11pt;
}

.title {
  padding-top: 24pt;
  color: #ffffff;
  font-weight: 700;
  font-size: 36pt;
  padding-bottom: 6pt;
  line-height: 1.0791666666666666;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}

.subtitle {
  padding-top: 18pt;
  color: #666666;
  font-size: 24pt;
  padding-bottom: 4pt;
  font-family: "Georgia";
  line-height: 1.0791666666666666;
  page-break-after: avoid;
  font-style: italic;
  orphans: 2;
  widows: 2;
  text-align: left;
}

li {
  color: #ffffff;
}

p {
  color: #ffffff;
}

h1 {
  color: #ffffff;
}

h2 {
  color: #ffffff;
}

h3 {
  color: #ffffff;
}

h4 {
  color: #ffffff;
}

h5 {
  color: #ffffff;
}

h6 {
  color: #ffffff;
}
